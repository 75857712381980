import React from 'react'

import { Container } from './commons'

const Footer = () => (
  <Container className="pt1 pb1 footer">
    <p>© {(new Date()).getFullYear()} Naveen Kumar Sangi. All Rights Reserved.</p>
    <a title="Creative Commons CC BY-NC-ND 4.0 License" href="https://creativecommons.org/licenses/by-nc-nd/4.0/" rel="noopener noreferrer" target="_blank">
      <img src="https://mirrors.creativecommons.org/presskit/buttons/88x31/svg/by-nc-nd.svg" alt="license CC-BY-NCND 4.0" />
    </a>
  </Container>
)

export default Footer
