import React from 'react';

const SolarSystem = () => {
  const parts = ['sun', 'mercury', 'venus', 'earth', 'mars', 'jupiter',
    'saturn', 'uranus', 'neptune', 'pluto', 'asteroids-belt']
  const divs = parts.map(part => <div className={part} key={part} />)
  return (
    <div className="solar-syst">{divs}</div>
  )
};

export default SolarSystem;
