import React from 'react'
import { Link } from 'gatsby'
import FontAwesome from 'react-fontawesome'

const Header = () => (
  <>
    <nav className="navigation">
      <Link activeClassName="active" to="/">
        <p>home</p>
        <FontAwesome name="home" size="lg" />
      </Link>
      <a target="_blank" rel='noopener noreferrer' href="https://blog.naveenkumarsangi.me">
        <p>blog</p>
        <FontAwesome name="rss-square" size="lg" />
      </a>
      <Link activeClassName="active" to="/timeline/">
        <p>about</p>
        <FontAwesome name="address-card" size="lg" />
      </Link>
      <Link activeClassName="active" to="/work/">
        <p>work</p>
        <FontAwesome name="paint-brush" size="lg" />
      </Link>
      <Link activeClassName="active" to="/testimonials/">
        <p>references</p>
        <FontAwesome name="quote-right" size="lg" />
      </Link>
    </nav>
  </>
)

export default Header
