import React from 'react'

import Header from '../components/header'
import Footer from '../components/footer'
import SolarSystem from '../components/solarsystem'
import Transition from '../components/transition'

import '../styles/main.scss'
import 'font-awesome/css/font-awesome.min.css'

const Layout = ({ children, location }) => (
  <main>
    <Header />
    <SolarSystem />
    <Transition location={location}>
      {children}
      <Footer />
    </Transition>
  </main>
)

export default Layout
